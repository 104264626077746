import React, { useContext } from 'react';
import './style.scss';
import Badge from '../Badge';
import { LangContext } from '../Layout';
import { ArrowForward } from '@material-ui/icons';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { MediaQuerySSR } from 'react-responsive-ssr';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: false,
  centerMode: true,
};

export const GhostPost = ({ publication }) => {
  var monthShortNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let date = new Date(publication.published_at);
  return (
    <a
      href={'https://blog.theodo.ma/' + publication.slug}
      target="_blank"
      rel="noopener noreferrer"
      className="blog__cont__publications__card"
    >
      {publication.image && (
        <Img
          className="blog__cont__publications__card__image"
          fluid={publication.image.childImageSharp?.fluid}
          alt={publication.title}
        />
      )}
      <div className="blog__cont__publications__card__avatar">
        {publication.authors && publication.authors[0].image && (
          <Img
            className="blog__cont__publications__card__avatar__img"
            alt={publication.authors[0].name}
            fluid={publication.authors[0].image.childImageSharp.fluid}
          />
        )}
      </div>
      <div className={'blog__cont__publications__card__info'}>
        <p className={'blog__cont__publications__card__info__type'}>
          {publication.tags.length != 0 ? publication.tags[0].name : 'tech'}
        </p>
        <div className={'blog__cont__publications__card__info__point'} />
        <p className={'blog__cont__publications__card__info__date'}>
          {`${date.getDate()} ${
            monthShortNames[date.getMonth()]
          } ${date.getFullYear()}`}
        </p>
      </div>
      <div className={'blog__cont__publications__card__title'}>
        {publication.title}
      </div>
      <div className="blog__cont__publications__card__content">
        {publication.excerpt && publication.excerpt.substring(0, 100) + '...'}
      </div>
      <div className="blog__cont__publications__card__read">
        Read Article
        <ArrowForward fontSize="small" className="materiel-icon" />
      </div>
    </a>
  );
};

const Blog = ({ blog, posts }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="blog">
      <div className="blog__top">
        <div className="blog__top__ticket">
          <Badge text="Publications" />
        </div>
        <p className="blog__top__title">{blog['title_' + language]}</p>
      </div>
      <div className={'blog__cont'}>
        <div className="blog__cont__publications">
          <MediaQuerySSR minWidth={1025}>
            {posts.map((post, index) => (
              <GhostPost publication={post.node} key={index} />
            ))}
          </MediaQuerySSR>
          <MediaQuerySSR maxWidth={1025}>
            <Slider {...settings}>
              {posts.map((post, index) => (
                <GhostPost publication={post.node} key={index} />
              ))}
            </Slider>
          </MediaQuerySSR>
        </div>
      </div>
      <div className="blog__link">
        <a
          className="blog__link__button"
          href="https://blog.theodo.ma/"
          target="_blank"
        >
          {blog['button_' + language]}
          <ArrowForward fontSize="small" className="materiel-icon" />
        </a>
      </div>
    </div>
  );
};
export default Blog;
